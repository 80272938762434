import { TaskPin } from './../shared/types/task.types';
import { SiteReplica } from './site-replica';
import axios from 'axios';
import { ReplicaService } from './replica-service';
import { getUrlParameterByName as getUrlParameterByName } from './helpers';
import { getLogger } from './logging';

const logger = getLogger('main');
// because same-origin policy (cross iframe javascript communication)
// will return:
// for "http://localhost:4200/" => "localhost"
// for "https://editor.stylers.cloud/" => "stylers.cloud"
document.domain = location.hostname.split('.').slice(-2).join('.');

const id = +getUrlParameterByName('id');

const replicaService = new ReplicaService(id);
const siteReplica = new SiteReplica(axios, replicaService);

const projectId = getUrlParameterByName('projectId');
const taskId = getUrlParameterByName('taskId');
const includeUnpublished = !!getUrlParameterByName('includeUnpublished');
const dateTime = getUrlParameterByName('dateTime');
const clusterDateTime = getUrlParameterByName('clusterDateTime');
const html = getUrlParameterByName('html');
const scrollTop = +getUrlParameterByName('scrollTop');
const scrollLeft = +getUrlParameterByName('scrollLeft');

// To highlight selected elements by selectors: string[]
// encode: encodeURIComponent( btoa( JSON.stringify( selectors ) ) )
const selectorsEncoded = getUrlParameterByName('selectors');
let selectors: string[] = [];
if (selectorsEncoded) {
    // decode
    try {
        selectors = JSON.parse(atob(selectorsEncoded));
    } catch (e) {
        logger.warn('Invalid "selectors" parameter value.');
    }
}

// more pins can be passed in format:
// [{ exactSelector: "...", left: 83, top: 28 }, ... ] => to JSON => to BASE64
// encode: encodeURIComponent( btoa( JSON.stringify( pins ) ) )
const pinsEncoded = getUrlParameterByName('pins');
let pins: TaskPin[] = [];
if (pinsEncoded) {
    // decode
    try {
        pins = JSON.parse(atob(pinsEncoded));
    } catch (e) {
        logger.warn('Invalid "pins" parameter value.');
    }
}

let debouncedProces: ReturnType<typeof setTimeout>;

if (html && projectId) {
    siteReplica
        .replicateSite(html, projectId, taskId, scrollTop, scrollLeft, selectors, pins, {
            taskId: taskId,
            dateTime: dateTime,
            clusterDateTime: clusterDateTime,
            includeUnpublished: includeUnpublished,
        })
        .then(() => {
            let isOpenedInPlatform = false;
            try {
                // try if this fail if not in platform (eg. in clients app)
                isOpenedInPlatform = replicaService.isOpenedInPlatform;
            } catch (e) {
                // do nothing
                return;
            }

            // block clicks on <a> and <button>
            document.addEventListener('click', e => {
                const activeEl = findLinkOrButton(e.target as HTMLElement);
                if (activeEl) {
                    // @ts-ignore
                    console.log(activeEl, activeEl.getAttribute('href'));
                    // @ts-ignore
                    const isLink = activeEl.nodeName === 'A' && activeEl.getAttribute('href') === '#';
                    // @ts-ignore
                    const isButton = activeEl.nodeName === 'BUTTON' && activeEl.form;

                    // block default action
                    if (isLink || isButton) {
                        e.stopPropagation();
                        e.preventDefault();
                        return;
                    }
                }
            });

            runWhenReady(processHTML);

            runWhenReady(() => {
                replicaService.createApi();
            });

            if (!isOpenedInPlatform) {
                logger.info('using passive mode');
                return; // <------------------------------- end here --------------
            }

            // --------------------------------------------------------------------

            function init() {
                replicaService.replicaReady();
            }

            runWhenReady(init);

            document.addEventListener('mouseover', e => {
                if (replicaService.isSelectionModeOn) {
                    replicaService.onReplicaElementHover(e.target as HTMLElement);
                }
            });
            document.addEventListener('mouseout', e => {
                if (replicaService.isSelectionModeOn) {
                    replicaService.onReplicaElementBlur();
                }
            });

            document.addEventListener(
                'scroll',
                e => {
                    if (replicaService.isSelectionModeOn) {
                        replicaService.repositionHighlightedElement();
                    }
                },
                true, // flag for global scroll listener
            );

            document.addEventListener('click', e => {
                if (replicaService.isSelectionModeOn) {
                    replicaService.onReplicaStopSelectionMode();
                    replicaService.onReplicaElementSelect(e.target as HTMLElement);
                    replicaService.stopSelectionMode();
                    e.preventDefault();
                } else {
                    replicaService.createHighlightElements();

                    // debounce (because e.g. click on label will emulate click in inner input)
                    clearTimeout(debouncedProces);
                    debouncedProces = setTimeout(() => {
                        // select anytime the document is clicked
                        replicaService.onReplicaElementSelect(e.target as HTMLElement);
                    }, 25);
                }

                // Special feature for "Onboarding" task.
                // Scroll to the next section timeouted (because it doesn't work immediately) when:
                // <a href="#section-medior-level"><label class="next-section-button" for="check-junior">Next</label></a>
                const target = e.target as HTMLElement;
                if (target && target.classList.contains('next-section-button') && target.parentNode.nodeName === 'A') {
                    setTimeout(() => {
                        // @ts-ignore
                        location.href = target.parentNode.href;
                    }, 100);
                }
            });

            window.onbeforeunload = () => {
                logger.info('window closing');
                replicaService.replicaClosing();
            };
        });
} else {
    logger.error('Can not replicate site without project id and hash of tokenized html.');
}

/** Find <a> or <button> element in given elemento or in its parents. */
function findLinkOrButton(el: HTMLElement): HTMLElement | null {
    while (el && el.nodeName !== 'A' && el.nodeName !== 'BUTTON') {
        el = el.parentNode as HTMLElement;
    }
    return el || null;
}

/** Special modifications to HTML replica. */
function processHTML() {
    // convert figma links into functional even in replica (for onboarding task)
    const links = document.querySelectorAll('a');
    links.forEach(link => {
        const href = link.getAttribute('href');
        const innerText = link.innerHTML; // prop innerText doesn't work properly
        if (
            (href === '#' || href === '') &&
            link.target === '_blank' &&
            (innerText.match(/https?\:\/\/www\.figma\.com\/file\/[a-z0-9]/i) ||
                innerText === 'https://www.figma.com/signup')
        ) {
            link.href = innerText;
        }
    });
}

function runWhenReady(fn: Function) {
    if (document.readyState === 'loading') {
        document.addEventListener('readystatechange', e => {
            if (document.readyState === 'interactive') {
                fn();
            }
        });
    } else {
        fn();
    }
}
