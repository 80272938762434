import { fixedFileNames, reusablesSorted } from './multifile-const';

export function compareFilePathsForCompile(a: string, b: string) {
    const aReusable = reusablesSorted.indexOf(a);
    const bReusable = reusablesSorted.indexOf(b);
    if (aReusable !== -1 || bReusable !== -1) {
        if (aReusable === -1) {
            return 1;
        }
        if (bReusable === -1) {
            return -1;
        }
        return aReusable - bReusable;
    }

    const aGlobal = a == fixedFileNames.GLOBAL_SASS_FILE_NAME || a == fixedFileNames.GLOBAL_SASS_FILE_NAME_2;
    const bGlobal = b == fixedFileNames.GLOBAL_SASS_FILE_NAME || b == fixedFileNames.GLOBAL_SASS_FILE_NAME_2;

    if ((aGlobal || bGlobal) && aGlobal != bGlobal) {
        if (aGlobal) return -1;
        if (bGlobal) return 1;
    }

    return a.localeCompare(b);
}
