import pino, { WriteFn } from 'pino';

export const consoleStylePlatform = 'color: #586EE0';
export const consoleStyleSpotEditor = 'color: #E051ED';
export const consoleStyleReplica = 'color: #9154F7';
export const consoleStyleSDK = 'color: #54C5F7';
export const consoleStyleClient = 'color: #29F0C0';
const consoleStyleModule = 'color: #888';
const consoleStyleReset = 0;

/**
 *
 * @param systemName
 * @param color
 * @param options
 * @returns baseLogger - use baseLogger.child() to create new hierarchical loggers
 *
 *
 * options.level - be careful browser has filtering depending on log level
 */
export function getBaseLogger(systemName: string, color: string, options: pino.ChildLoggerOptions) {
    return pino({
        level: options.level,
        browser: {
            serialize: true,
            asObject: true,

            write: {
                debug: consoleFn(color, 'debug'),
                trace: consoleFn(color, 'trace'),
                info: consoleFn(color, 'info'),
                warn: consoleFn(color, 'warn'),
                error: consoleFn(color, 'error'),
                fatal: consoleFn(color, 'fatal'),
            },
            // transmit: {
            //     send(level, logEvent) {
            //         console.log(level, logEvent);
            //     },
            // },
        },

        transport: {
            target: 'pino-pretty',
        },
    }).child({ system: systemName }, options);
}

function consoleFn(color: string, methodName: 'debug' | 'trace' | 'info' | 'warn' | 'error' | 'fatal'): WriteFn {
    return ((o: LogObject) => {
        const { system, module, msg, level, time, collapsed, ...rest } = o;
        const system_str = `[${system}]`;
        const module_str = module ? `[${module}]` : '';
        const isRestEmpty = Object.keys(rest).length === 0;

        if (collapsed) {
            console.groupCollapsed(
                '%c%s%c%s%c %s',
                color,
                system_str,
                consoleStyleModule,
                module_str,
                consoleStyleReset,
                msg.split(/\%|\n|\r/)[0],
            );
        }
        Function.prototype.bind.call(
            methodName === 'fatal' ? console.log : console[methodName],
            console,
            `%c%s%c%s%c %O${isRestEmpty ? '' : ' %O'}`,
            color,
            system_str,
            consoleStyleModule,
            module_str,
            consoleStyleReset,
            msg,
            ...(isRestEmpty ? [] : [rest]),
        )();
        if (collapsed) {
            console.groupEnd();
        }
    }) as WriteFn;
}

interface LogObject {
    system: string;
    module: string;
    level: number;
    msg: string;
    time: number;
    collapsed?: boolean;
    [key: string]: any;
}
