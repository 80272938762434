// @TODO maybe is better to use element.getBoundingClientRect()
export function getElementMBR(
    el: HTMLElement,
    toParentSelector: string | null = null,
    expander = 0,
    offsetX = 0,
    offsetY = 0,
) {
    let x = 0;
    let y = 0;
    const width = el.offsetWidth;
    const height = el.offsetHeight;
    let position;
    const win = el.ownerDocument.defaultView || window;
    while (el) {
        const computedStyle = win.getComputedStyle(el);
        x += el.offsetLeft - el.scrollLeft + parseFloat(computedStyle.borderLeftWidth);
        y += el.offsetTop - el.scrollTop + parseFloat(computedStyle.borderTopWidth);
        position = computedStyle.position;
        if (position === 'fixed') {
            break;
        }
        el = el.offsetParent as HTMLElement;

        if (toParentSelector && el.matches(toParentSelector)) {
            break;
        }
    }
    return {
        // position and size
        left: x + offsetX,
        top: y + offsetY,
        width,
        height,
        // MBR (minimum bounding rectangle)
        x1: x - expander + offsetX,
        y1: y - expander + offsetY,
        x2: x + width + expander + offsetX,
        y2: y + height + expander + offsetY,
        isFixed: el && position === 'fixed',
    };
}

// if you puts in e.g. SVG element, it will return first HTML parent element
// Notice: <svg> element does not support offsetLeft too
export function getClosestHTMLElement(el: HTMLElement) {
    while (el && el.offsetLeft === undefined) {
        el = el.parentNode as HTMLElement;
    }
    return el;
}
