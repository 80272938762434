import { TaskPin } from './../shared/types/task.types';
import { isIgnoredDomain } from './../shared/utils/ignored-domains';
import { environment } from './environments/environment';
import { AxiosInstance } from 'axios';
import { DOMagicI } from '../shared/types/domagic.types';
import { Message, Origin } from '../shared/types/message.types';
import { PlatformMessage } from '../shared/types/messages/platform-message.types';
import { ReplicaMessage } from '../shared/types/messages/replica-message.types';
import { createPin, createSelectorBox, getPinStyleElement } from './highlighting-elements';
import { getElementMBR } from '../shared/utils/get-element-mbr';
import { DOModfifierProps } from '../shared/types/interfaces/domodifier.types';
import { ReplicaService } from './replica-service';
import stripCssComments from 'strip-css-comments';

declare global {
    interface Window {
        DOMagic: DOMagicI;
    }
}

function isPlatformMessage(data: Message): data is PlatformMessage.PlatformMessage {
    return data.origin === Origin.platform;
}
function isCountMessage(
    data: PlatformMessage.PlatformMessage,
): data is PlatformMessage.PlatformMessage & PlatformMessage.SelectorMatchCounts {
    return data.action === PlatformMessage.ActionTypes.selectorMatchCounts;
}
export class SiteReplica {
    private projectId: string;
    private taskId: string;
    private domodifierProps?: DOModfifierProps;
    private axios: AxiosInstance;

    private replicaService: ReplicaService;

    constructor(axios: AxiosInstance, replicaService: ReplicaService) {
        this.axios = axios;
        this.replicaService = replicaService;
    }

    replicateSite(
        tokenizedHtml: string,
        projectId: string,
        taskId: string,
        scrollTop: number,
        scrollLeft: number,
        /** To highlight selected elements. */
        selectors: string[] = [],
        pins: TaskPin[] = [],
        domodifierProps: DOModfifierProps,
    ) {
        this.projectId = projectId;
        this.taskId = taskId;
        this.domodifierProps = domodifierProps;

        return this.getSiteReplica(tokenizedHtml)
            .then(html => {
                if (this.taskId) {
                    html = this.addDOMagicScript(html);
                }
                if (pins.length > 0 || selectors.length > 0) {
                    html = this.addPinAndBoxAmimationStyle(html);
                }
                return html;
            })
            .then(html => this.writeHtml(html))
            .then(() => {
                window.addEventListener('load', () => {
                    this.setInputsValue();
                    this.scrollDocument(scrollTop, scrollLeft);
                    this.scrollElements();

                    this.createSelectorBoxes(selectors);
                    this.createPins(pins);
                });

                window.addEventListener(
                    'message',
                    (message: MessageEvent<Message>) => {
                        if (isPlatformMessage(message.data)) {
                            if (isCountMessage(message.data)) {
                                try {
                                    const responseSelectors = window['DOMagic'].getSelectorMatchesCount(
                                        message.data.selectors,
                                        message.data.sequence,
                                        message.data.id,
                                    );
                                    const response: ReplicaMessage.ReplicaMessage = {
                                        origin: Origin.replica,
                                        action: ReplicaMessage.ActionTypes.selectorMatchCounts,
                                        payload: {
                                            modificationId: message.data.id,
                                            sequence: message.data.sequence,
                                            selectors: responseSelectors,
                                        },
                                    };
                                    this.replicaService.postReplicaMessage(response);
                                } catch (e) {
                                    console.log('Replica error', e);
                                }
                            } else if (message.data.action === 'setModifications') {
                                window['DOMagic'].setModifications(message.data.modifications);
                            } else if (message.data.action === 'removeModification') {
                                window['DOMagic'].removeModification(message.data.id);
                            } else if (message.data.action === 'addModification') {
                                window['DOMagic'].addModification(message.data.modification);
                            } else if (message.data.action === 'turnOnModification') {
                                window['DOMagic'].turnOnModification(message.data.id);
                            } else if (message.data.action === 'turnOffModification') {
                                window['DOMagic'].turnOffModification(message.data.id);
                            } else if (message.data.action === 'turnOffAllModifications') {
                                window['DOMagic'].turnOffAllModifications();
                            } else if (message.data.action === 'turnOnAllModifications') {
                                window['DOMagic'].turnOnAllModifications();
                            }
                        }
                    },
                    false,
                );
            });
    }

    private addDOMagicScript(html: string) {
        const script = `<script src="${getDomodifierSrc(this.projectId, {
            taskId: this.taskId,
            ...this.domodifierProps,
        })}"></script>`;
        // const script = `<script src="http://localhost:8080/main.js"></script><script>DOMagic.startObservingDOM();</script>`; // this is for localhost platform testing
        html = html.replace('</head>', `${escapeRegExpReplacement(script)}</head>`);

        return html;
    }

    private addPinAndBoxAmimationStyle(html: string) {
        html = html.replace('</head>', `${escapeRegExpReplacement(getPinStyleElement())}</head>`);
        return html;
    }

    private writeHtml(html: string) {
        // fix HTML glitch (when there is some html between <html> and <head>, it will make <head> element empty and all its content is moved to <body>)
        // maybe this is not the right place to fix it, but this is a hot fix
        // @TODO: find how can this happen and such replica stored, e.g. https://assets.stylers.cloud/daf21d60-2474-11ed-b2ac-e9438f4c40d2/222232408c84ea19a57b097185414ed3
        const htmlGlitch = html.match(/(.*<!\-\-\[if.*)?(?<=<html.*?>).*?(?=<head)/im);
        // ignore if contains comments hacks <!--[if...
        if (htmlGlitch && htmlGlitch[0] && htmlGlitch[0].indexOf('<!--[if') === -1) {
            console.error(
                'HTML glitch in replica: ',
                htmlGlitch,
                'This html was found between <html> and <head> tags and was removed.',
            );
            html = html.replace(/(?<=<html.*?>).*?(?=<head)/im, '');
        }

        document.open();
        document.write(html);
        document.close();

        document.documentElement.setAttribute('replica', '');
    }

    private scrollDocument(scrollTop: number, scrollLeft: number) {
        const html = document.getElementsByTagName('html')[0];
        if (scrollTop) {
            html.scrollTop = scrollTop;
        }
        if (scrollLeft) {
            html.scrollLeft = scrollLeft;
        }
    }

    private scrollElements() {
        const traverseElements = (el: Element) => {
            if (el.hasAttribute('stylers-cloud-scroll-value-left')) {
                el.scrollLeft = parseInt(el.getAttribute('stylers-cloud-scroll-value-left'), 10);
                el.removeAttribute('stylers-cloud-scroll-value-left');
            }
            if (el.hasAttribute('stylers-cloud-scroll-value-top')) {
                el.scrollTop = parseInt(el.getAttribute('stylers-cloud-scroll-value-top'), 10);
                el.removeAttribute('stylers-cloud-scroll-value-top');
            }

            let childIndex = 0;
            while (childIndex < el.children.length) {
                traverseElements(el.children[childIndex]);
                childIndex++;
            }
        };

        Array.from(document.children).forEach(el => {
            traverseElements(el);
        });
    }

    private setInputsValue() {
        const inputs = document.getElementsByTagName('input');
        Array.from(inputs).forEach(input => {
            const stylersValue = input.getAttribute('stylers-cloud-input-value');
            input.removeAttribute('stylers-cloud-input-value');
            input.value = stylersValue;
        });
    }

    private createSelectorBoxes(selectors: string[]) {
        /*
        We wrap all selector box elements in wrapper (div[stylers-cloud=selected-element-wrapper])
        this wrapper hides overflow of selector box elements if it is more than whole page, dancing scrollbars

        We set html position=relative so 100% on wrapper is relative to html
        */
        const html = document.getElementsByTagName('html')[0];
        html.style.position = 'relative';
        const selBoxWrapperEl = document.createElement('div');
        selBoxWrapperEl.setAttribute('stylers-cloud', 'selected-element-wrapper');
        const s = selBoxWrapperEl.style;
        s.width = '100%';
        s.height = '100%';
        s.overflow = 'hidden';
        s.position = 'absolute';
        s.left = '0';
        s.top = '0';
        s.pointerEvents = 'none';
        s.zIndex = (2147483647 - 1000).toString(); // maximum - 1k

        const elements: HTMLElement[] = [];
        for (let i = 0; i < selectors.length; i++) {
            const elms = document.querySelectorAll(selectors[i]);
            for (let j = 0; j < elms.length; j++) {
                const selBoxEl = createSelectorBox();
                selBoxWrapperEl.appendChild(selBoxEl);
                elements.push(selBoxEl);
            }
        }

        document.body.appendChild(selBoxWrapperEl);

        // initial repositioning
        this.repositionSelectorBoxes(selectors, elements);

        // repositioning when window resizes
        const timeoutProcesses: ReturnType<typeof setTimeout>[] = [];
        const processCount = 6;
        const processDelay = 300; //ms

        window.addEventListener('resize', () => {
            timeoutProcesses.map(clearTimeout);

            this.repositionSelectorBoxes(selectors, elements);
            // repeat timeouted because CSS transitions and rendering delays (e.g. loading iamges)
            for (let i = 0; i < processCount; i++)
                timeoutProcesses[i] = setTimeout(() => {
                    this.repositionSelectorBoxes(selectors, elements);
                }, processDelay * i);
        });
    }

    private repositionSelectorBoxes(selectors: string[], elements: HTMLElement[]) {
        let i = 0;
        let k = 0;
        while (i < elements.length) {
            const targetEls = document.querySelectorAll(selectors[k++]);
            for (let j = 0; j < targetEls.length; j++) {
                const el = elements[i];
                if (targetEls[j] && isVisibleInDocument(targetEls[j] as HTMLElement)) {
                    const mbr = getElementMBR(targetEls[j] as HTMLElement);
                    el.style.visibility = 'visible';
                    el.style.left = mbr.left + 'px';
                    el.style.top = mbr.top + 'px';
                    el.style.width = mbr.width + 'px';
                    el.style.height = mbr.height + 'px';
                    el.style.position = mbr.isFixed ? 'fixed' : 'absolute';
                } else {
                    el.style.visibility = 'hidden';
                }
                i++;
            }
        }
    }

    private createPins(pins: TaskPin[]) {
        const elements: HTMLElement[] = [];
        for (let i = 0; i < pins.length; i++) {
            const pin = pins[i];
            // if more than one pin, mark them A, B, C, D, ...
            const pinEl = createPin(pins.length > 1 ? String.fromCharCode(65 + i) : '');
            document.body.appendChild(pinEl);
            elements.push(pinEl);
        }

        // initial repositioning
        this.repositionPins(pins, elements);

        // repositioning when window resizes
        const timeoutProcesses: ReturnType<typeof setTimeout>[] = [];
        const procCount = 6;
        const procDelay = 300; //ms

        window.addEventListener('resize', () => {
            timeoutProcesses.map(clearTimeout);

            this.repositionPins(pins, elements);
            // repeat timeouted because transitions and delays
            for (let i = 0; i < procCount; i++)
                timeoutProcesses[i] = setTimeout(() => {
                    this.repositionPins(pins, elements);
                }, procDelay * i);
        });
    }

    private repositionPins(pins: TaskPin[], elements: HTMLElement[]) {
        for (let i = 0; i < pins.length; i++) {
            const pin = pins[i];
            const el = elements[i];
            const targetEl = document.querySelector(pin.exactSelector) as HTMLElement;
            if (targetEl && isVisibleInDocument(targetEl)) {
                const mbr = getElementMBR(targetEl);
                el.style.visibility = 'visible';
                el.style.left = mbr.left + pin.left + 'px';
                el.style.top = mbr.top + pin.top + 'px';
                el.style.position = mbr.isFixed ? 'fixed' : 'absolute';
            } else {
                el.style.visibility = 'hidden';
            }
        }
    }

    getSiteReplica(tokenizedHtml: string, projectId?: string) {
        this.projectId = projectId || this.projectId;

        return this.fetchHtml(tokenizedHtml)
            .then(html => {
                return this.replaceAssetUrls(html);
            })
            .then(html => {
                return this.replaceLinkTagsWithStyleTags(html);
            })
            .then(html => {
                return this.replaceImportsInStyleTags(html);
            });
    }

    /** Replaces asset tokens {{src:.....}} with CDN URLs in given styleSheet string. */
    private replaceAssetUrls(styleSheet: string) {
        const srcAssets = styleSheet.match(/{{src:.*?}}/gm) || [];

        srcAssets.forEach(srcAsset => {
            const asset = srcAsset.replace('{{src:', `${environment.cdnUrl}${this.projectId}/`).replace('}}', '');

            // todo test
            if (asset.includes('unavailable') || asset.includes('ignored') || asset.includes('invalid')) {
                styleSheet = styleSheet.replace(srcAsset, '#');
            } else {
                styleSheet = styleSheet.replace(srcAsset, escapeRegExpReplacement(asset));
            }
        });

        return styleSheet;
    }

    private replaceLinkTagsWithStyleTags(html: string) {
        const linkTags = html.match(/<link.*?href=".*?".*?>/gm) || [];
        console.log(linkTags);
        const promiseArr: Promise<void>[] = [];

        linkTags.forEach(linkTag => {
            const isGlobalStylesTag = linkTag.match(/stylers-cloud="global-css"/);

            const media = linkTag.match(/media="(.*?)"/);
            const assetUrl = linkTag.match(/href="(.*?)"/)[1];

            // if asset is from our CDN
            if (assetUrl.includes(environment.cdnUrl)) {
                promiseArr.push(
                    this.fetchStyleSheet(assetUrl)
                        .then(styleSheet => {
                            // fix when CSS contains SVG in url() and that svg contains <style> ... </style> tags
                            styleSheet = styleSheet.replace(/\<style/g, '%3Cstyle').replace(/\<\/style/g, '%3C/style');
                            return this.replaceAssetUrls(styleSheet);
                        })
                        .then(styleSheet => {
                            return `<style${media ? ' ' + media[0] : ''}${
                                isGlobalStylesTag ? ' stylers-cloud="global-css"' : ''
                            }>\n${styleSheet}\n</style>`;
                        })
                        .then(async styleTagContent => {
                            return await this.replaceImportsInStyleTag(styleTagContent);
                        })
                        .then(newHtml => {
                            html = html.replace(linkTag, escapeRegExpReplacement(newHtml));
                        }),
                );
            } else {
                // add crossorigin="anonymous" to link tag
                const newLinkTag = linkTag.replace(/(<link .*rel=\"stylesheet\")/, '$1 crossorigin="anonymous"');
                html = html.replace(linkTag, escapeRegExpReplacement(newLinkTag));
            }
        });

        return Promise.allSettled(promiseArr).then(() => html);
    }

    private replaceImportsInStyleTags(html: string) {
        const styleTags = html.match(/<style.*?<\/style>/gms) || [];
        const promiseArr: Promise<void>[] = [];
        styleTags.forEach(styleContent => {
            // quick check of "@import" to prevent out-of-memory crash of Chrome (DOM Inspector) with style around 10MB (226 stylesheets)
            if (styleContent.indexOf('@import') != -1) {
                promiseArr.push(
                    this.replaceImportsInStyleTag(styleContent).then(content => {
                        html = html.replace(styleContent, escapeRegExpReplacement(content));
                    }),
                );
            }
        });

        return Promise.allSettled(promiseArr).then(() => html);
    }

    private replaceImportsInStyleTag(styleTagContent: string) {
        const importAssetUrls = this.findImportAssetUrls(styleTagContent);
        const promiseArr: Promise<string>[] = [];

        importAssetUrls
            // do not expand ignored CDN urls (just leave @import-s)
            .filter(assetUrl => !isIgnoredDomain(assetUrl))

            // expand @imports into separate <style> elements
            .forEach(assetUrl => {
                // todo TEST
                styleTagContent = styleTagContent.replace(
                    new RegExp('@import.*?' + escapeRegExp(assetUrl) + '.*?;', 'g'),
                    '',
                );
                promiseArr.push(
                    this.fetchStyleSheet(assetUrl)
                        .then(content => this.replaceAssetUrls(content))
                        .then(content => `<style>\n${content}\n</style>`)
                        .then(async content => await this.replaceImportsInStyleTag(content)),
                );
            });

        return Promise.allSettled(promiseArr).then(results => {
            const resultValues = results.map(result => ('value' in result ? result.value : ''));
            return `${resultValues.join('\n')}${resultValues.length > 0 ? '\n' : ''}${styleTagContent}`;
        });
    }

    private findImportAssetUrls(content: string) {
        const imports = stripCssComments(content).match(/@import.*?;/gm) || []; // TODO TEST
        const assetUrls: string[] = [];

        imports.forEach(importMatch => {
            const assetMatch = importMatch.match(/(@import *)((url\()?(["'])?)?(.*?)((["'])?\)?)(;)/);
            if (assetMatch) {
                const assetUrl = assetMatch[5];
                if (assetUrl) {
                    assetUrls.push(assetUrl);
                }
            } else {
                // this must always work, if not then regular expression above is not correct
                console.warn('ImportMatch failed: ', importMatch);
                console.warn('Regular expression must be fixed!');
            }
        });
        return assetUrls;
    }

    private fetchHtml(tokenizedHtml: string) {
        return this.axios
            .get<string>(`${environment.cdnUrl}${this.projectId}/${tokenizedHtml}`, {
                responseType: 'text',
            })
            .then(response => response.data);
    }

    private fetchStyleSheet(path: string) {
        return this.axios
            .get<string>(`${path}`, {
                responseType: 'text',
            })
            .then(response => response.data)
            .catch(error => {
                console.log(error);
                return '';
            });
    }
}

function isVisibleInDocument(el: HTMLElement) {
    return el.offsetLeft !== 0 || el.offsetTop !== 0 || el.offsetWidth !== 0 || el.offsetHeight !== 0;
}

function getDomodifierSrc(projectId: string, props: DOModfifierProps) {
    const params = new URLSearchParams();
    if (props.taskId) params.append('taskId', props.taskId);
    if (props.dateTime) params.append('dateTime', props.dateTime);
    if (props.clusterDateTime) params.append('clusterDateTime', props.clusterDateTime);
    if (props.includeUnpublished) params.append('includeUnpublished', '1');
    return `${environment.apiUrl}v1/projects/${projectId}/domodifier?${params}`;
}

function escapeRegExp(stringToGoIntoTheRegex: string) {
    return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

/**
 * since replacement in String.replace(pattern, replacement) uses `$` as special character, so we have to escape it
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#specifying_a_string_as_the_replacement:~:text=%24%24
 *
 * @param replacement string to be literally used as replacement
 * @returns escaped `replacement` string to be used in String.replace()
 */
function escapeRegExpReplacement(replacement: string) {
    // since $ is special character we have to escape it (twice) so it gets replaced with $$
    return replacement.replace(/\$/g, '$$$$');
}
