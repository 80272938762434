let _duration = 0;
let _progressBar: HTMLElement | null = null;
const BLUE_GRADIENTS = 'linear-gradient(90deg, #0072ff, #00d0ff, #0072ff)';
const RED_GRADIENTS = 'linear-gradient(90deg, #f00, #fa0, #f00, #fa0, #f00)';
let _interval: ReturnType<typeof setInterval>;
let _timeout: ReturnType<typeof setTimeout>;
let _start = 0;
const MIN_DURATION = 200; //ms

export function startProgressBar(previousDurationMs: number) {
    if (previousDurationMs < MIN_DURATION) previousDurationMs = MIN_DURATION;
    _createProgressBar();
    _duration = previousDurationMs * 0.85;
    _progressBar.style.width = '0';
    _progressBar.style.backgroundImage = BLUE_GRADIENTS;
    _progressBar.style.animationDuration = '2s';
    _progressBar.style.animationDelay = '0s';

    clearInterval(_interval);
    clearTimeout(_timeout);
    _start = Date.now();

    _interval = setInterval(() => {
        const time = Date.now() - _start;
        _progressBar.style.width = (_time2width(time / previousDurationMs) * 100).toFixed(2) + '%';
    }, 50);
}

export function successProgressBar() {
    _createProgressBar();
    const time = Date.now() - _start;
    if (time < MIN_DURATION) {
        _progressBar.style.width = '100%';
        _timeout = setTimeout(() => {
            successProgressBar();
        }, MIN_DURATION - time);
        return;
    }

    clearInterval(_interval);
    _progressBar && _progressBar.parentNode && _progressBar.parentNode.removeChild(_progressBar);
}

export function errorProgressBar() {
    _createProgressBar();
    clearInterval(_interval);
    clearTimeout(_timeout);
    _progressBar.style.width = '100%';
    _progressBar.style.animationDuration = '0.5s';
    _progressBar.style.animationDelay = '1s';
    _progressBar.style.backgroundImage = RED_GRADIENTS;
    _timeout = setTimeout(() => {
        _progressBar && _progressBar.parentNode && _progressBar.parentNode.removeChild(_progressBar);
    }, 2000);
}

function _createProgressBar() {
    if (!_progressBar) {
        _progressBar = document.createElement('div');
        _progressBar.setAttribute('stylers-cloud', 'progress-bar');
        let s = _progressBar.style;
        // safety unsets
        s.margin = '0';
        s.padding = '0';
        s.border = '0';
        s.transform = 'none';
        s.opacity = '1';
        s.right = 'auto';
        s.bottom = 'auto';
        s.outline = 'none';
        s.boxShadow = 'none';

        // main style
        s.position = 'fixed';
        s.height = '2px';
        s.left = '0';
        s.top = '0';
        s.zIndex = '2147483646';
        s.pointerEvents = 'none';
        s.backgroundSize = '200% 100%';
        s.animation = 'gradient-progressbar-flow 2s linear infinite';
        s.width = '80%';

        _progressBar.innerHTML = `<style>
            @keyframes gradient-progressbar-flow {
                0% { background-position: 100% 50%; }
                100% { background-position: -100% 50%; }
            }
            </style>`;
    }
    document.body.appendChild(_progressBar);
}

function _time2width(t: number) {
    const linearPartTill = 0.95;
    // linear part 1:1
    if (t <= linearPartTill) return t;

    // infinite limit to 1
    return linearPartTill + (1 - 1 / (t + 1)) * (1 - linearPartTill);
}
