import { Origin } from '../message.types';
import { Modification, ModificationSelector } from '../modification.types';

export namespace ReplicaMessage {
    export type ReplicaMessage = {
        origin: Origin.replica;
        // action: T;
    } & Actions;

    export enum ActionTypes {
        selectorMatchCounts = 'selectorMatchCounts',
    }

    export type Actions = SelectorMatchCounts;

    interface SelectorMatchCounts {
        action: ActionTypes.selectorMatchCounts;
        payload: {
            selectors: {
                selector: ModificationSelector;
                count: number;
            }[];
            sequence: Modification['sequence'] | null;
            modificationId: Modification['id'];
        };
    }
}
