import { environment } from './environments/environment';
import { consoleStyleReplica, getBaseLogger } from '../shared/utils/logging';

const baseLogger = getBaseLogger('Replica', consoleStyleReplica, { level: 'trace' });

export function getLogger(moduleKey: string) {
    return baseLogger.child({ module: moduleKey });
}

export function todo(msg: string) {
    if (!environment.production) {
        baseLogger.warn('TODO: ' + msg);
    }
}
