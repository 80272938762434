export const fixedFileNames = {
    GLOBAL_SASS_FILE_NAME: '/globals/main.sass',
    GLOBAL_SASS_FILE_NAME_2: '/globals/_main.sass',
    REUSABLES_MIXINS_FILE_NAME: '/reusables/_mixins.sass',
    REUSABLES_VARIABLES_FILE_NAME: '/reusables/_variables.sass',
    REUSABLES_FUNCTIONS_FILE_NAME: '/reusables/_functions.sass',
};

export const reusablesSorted = [
    fixedFileNames.REUSABLES_FUNCTIONS_FILE_NAME,
    fixedFileNames.REUSABLES_VARIABLES_FILE_NAME,
    fixedFileNames.REUSABLES_MIXINS_FILE_NAME,
];
