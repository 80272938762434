import { Origin } from '../message.types';
import { Modification, ModificationDetail, ModificationSelector } from '../modification.types';

export namespace PlatformMessage {
    export type PlatformMessage = {
        origin: Origin.platform;
    } & Actions;

    export enum ActionTypes {
        selectorMatchCounts = 'selectorMatchCounts',
        turnOffModification = 'turnOffModification',
        turnOnModification = 'turnOnModification',
        turnOnAllModifications = 'turnOnAllModifications',
        turnOffAllModifications = 'turnOffAllModifications',
        addModification = 'addModification',
        removeModification = 'removeModification',
        setModifications = 'setModifications',
        applyCss = 'applyCss',
    }

    export type Actions =
        | SelectorMatchCounts
        | ApplyCss
        | TurnOffModification
        | TurnOnModification
        | TurnOnAllModifications
        | TurnOffAllModifications
        | AddModification
        | RemoveModification
        | SetModifications;

    export interface SelectorMatchCounts {
        action: ActionTypes.selectorMatchCounts;
        selectors: ModificationSelector[];
        sequence: Modification['sequence'] | null;
        id?: Modification['id'];
    }

    interface ApplyCss {
        action: ActionTypes.applyCss;
        css: string;
    }

    interface TurnOffModification {
        action: ActionTypes.turnOffModification;
        id: Modification['id'];
    }

    interface TurnOnModification {
        action: ActionTypes.turnOnModification;
        id: Modification['id'];
    }

    interface TurnOnAllModifications {
        action: ActionTypes.turnOnAllModifications;
    }

    interface TurnOffAllModifications {
        action: ActionTypes.turnOffAllModifications;
    }

    interface AddModification {
        action: ActionTypes.addModification;
        modification: ModificationDetail;
    }

    interface RemoveModification {
        action: ActionTypes.removeModification;
        id: Modification['id'];
    }

    interface SetModifications {
        action: ActionTypes.setModifications;
        modifications: ModificationDetail[];
    }
}
