const ignoredDomains = [
    // css files
    'fonts.googleapis.com',
    // map/image files
    'maps.googleapis.com',
    // font files
    'fonts.gstatic.com',
    // font files - e.g. https://cdn.shopify.com/s/files/1/0156/0327/t/11/assets/fonts.css
    'cdn.shopify.com',
];

const allowedProtocols = ['http://', 'https://', '//'];

/** Test if it is one of the domains which we don't download files. */
export function isIgnoredDomain(url: string): boolean {
    for (var i = 0; i < ignoredDomains.length; i++) {
        const index = url.indexOf('://' + ignoredDomains[i] + '/');
        // if after 'http' or 'https' position
        if (index == 4 || index === 5) {
            return true;
        }
    }
    return false;
}

/**
 * @return false when protocol is allowed, true otherwise
 */
export function isIgnoredProtocol(url: string): boolean {
    for (var i = 0; i < allowedProtocols.length; i++) {
        const index = url.indexOf(allowedProtocols[i]);
        // if after 'http' or 'https' position
        if (index == 0) {
            return false;
        }
    }
    return true;
}

/**
 * Test if URL comes from current projects CDN = is already stored and served from us.
 * Asset hash is not check intentionaly - because we have a problem that sometimes the asset hash doesn't match the file name.
 */
export function isOurCDN(url: string, cdnProjectUrl: string): boolean {
    return url.indexOf(cdnProjectUrl) === 0;
}

/**
 * Returns asset hash from URL of our CDN or empty string if nto matched.
 * (please test url with function isOurCDN at first)
 */
// example:
// input: https://assets.stylers.cloud/01a02690-00ef-11ed-b2ae-75e171527e9c/37dddc8c378a9ae7d2f66851a0fa58ac
// output: 37dddc8c378a9ae7d2f66851a0fa58ac
export function getHashFromOurCDN(url: string): string | false {
    // positive look-behind for UUID (project id) and match hash 32 char hex at the end
    const match = url.match(/(?<=\/[a-z0-9]{8}\-[a-z0-9]{4}\-[a-z0-9]{4}\-[a-z0-9]{4}\-[a-z0-9]{12}\/)[a-z0-9]{32}$/);
    return match ? match[0] : false;
}
