import { isStylersStyle } from './stylers-style';

export function disableStyleElement(
    styleElement: HTMLStyleElement,
    removedElements: StoredStyleElement[],
    origin: StyleOrigin = 'clients',
) {
    if (origin === 'all' || isStylersStyle(styleElement) === (origin === 'stylers')) {
        // @ts-ignore
        // special flag for <style stylers-cloud="global-css" when turned off
        if (styleElement.turnedOff !== true) {
            disableElement(styleElement, removedElements);
        }
    }
}

export function disableLinkStylesheet(
    linkElement: HTMLLinkElement,
    removedElements: StoredStyleElement[],
    origin: StyleOrigin = 'clients',
) {
    if (linkElement.rel === 'stylesheet') {
        if (origin === 'all' || isStylersStyle(linkElement) === (origin === 'stylers')) {
            // @ts-ignore
            // special flag for <style stylers-cloud="global-css" when turned off
            if (linkElement.turnedOff !== true && !isIgnoredStyle(linkElement)) {
                disableElement(linkElement, removedElements);
            }
        }
    }
}

export function disableElement(element: HTMLStyleElement | HTMLLinkElement, removedElements: StoredStyleElement[]) {
    const isAlreadyDisabled = removedElements.filter(item => item.element === element).length > 0;
    if (isAlreadyDisabled) {
        return;
    }
    const record = {
        element,
        elementDisabled: element.disabled,
        sheetDisabled: !!(element.sheet && element.sheet.disabled), // link element does have sheet object==null when element is disabled
        parentNode: element.parentNode,
        nextSibling: element.nextSibling,
    };
    removedElements.push(record);

    /// we need remove whole element to turn off styles immediately...
    element.parentNode.removeChild(element);

    // // ...because disabled when page loads does not work (for LINK sometimes, probably bug)
    // // or it blinks (style) before the style is removed
    // if (element.sheet) {
    //     element.sheet.disabled = true;
    // }
    // // element.disabled = true;

    // // but we want it in the document, so we will attach it back to DOM in few ms
    // setTimeout(() => {
    //     // record.parentNode.insertBefore(record.element, record.previousSibling);
    //     // element.disabled = true;
    //     if (element.sheet) {
    //         element.sheet.disabled = true;
    //     }
    //     // element.disabled = true;
    // }, 100);
}

export function disableAllStyles(removedElements: StoredStyleElement[], origin: StyleOrigin = 'clients') {
    let styles = document.getElementsByTagName('style');
    // convert to a real array
    // not using HTMLCollection because removing element from document will remove item from HTMLCollection (weird - Chrome).
    for (const style of Array.prototype.slice.call(styles)) {
        disableStyleElement(style, removedElements, origin);
    }

    styles = document.getElementsByTagName('link');
    // convert to a real array
    // not using HTMLCollection because removing element from document will remove item from HTMLCollection (weird - Chrome).
    for (const style of Array.prototype.slice.call(styles)) {
        disableLinkStylesheet(style, removedElements, origin);
    }
}

export function enableAllStyles(removedElements: StoredStyleElement[]) {
    // append all removed styles in reverse order (because previousSibling - it could be style too)
    for (let i = removedElements.length - 1; i >= 0; i--) {
        const style = removedElements[i];
        // style.element.disabled = style.elementDisabled;
        // if (style.element.sheet) {
        //     style.element.sheet.disabled = style.sheetDisabled;
        // }
        if (!isAttachedToDocument(style.element)) {
            const isStillSiblingOfParent = style.nextSibling?.parentNode === style.parentNode;
            style.parentNode.insertBefore(style.element, isStillSiblingOfParent ? style.nextSibling : null);
        }
        // remove last element
        removedElements.pop();
    }
}

function isIgnoredStyle(styleEl: HTMLStyleElement | HTMLLinkElement): boolean {
    if (styleEl instanceof HTMLLinkElement && styleEl.rel === 'stylesheet') {
        return styleEl.href.includes('font') || styleEl.href.includes('icon') || styleEl.href.includes('cdn');
    } else {
        return false;
    }
}

let changedClientsCssRules: CustomStyleRule[] = [];
let currentClientsFilteredElements: Element[] = [];
const IGNORE_CLIENTS_CSS_RULES_ATTRIBUTE = 'ignore-css-rules-clients';

/** Turn off (change) all CSS rules matching given elements (for clients style). */
export function filterClientsStyleForElements(filterElements: Element[]) {
    // at first undo previous filtering
    unfilterClientsStyleForElements();

    // mark given elements with special attribute
    currentClientsFilteredElements = [...filterElements];
    currentClientsFilteredElements.forEach(el => {
        el.setAttribute(IGNORE_CLIENTS_CSS_RULES_ATTRIBUTE, '');
    });

    const styles = document.querySelectorAll('style');
    for (let i = 0; i < styles.length; i++) {
        if (!isStylersStyle(styles[i])) {
            changedClientsCssRules = [
                ...changedClientsCssRules,
                ...filterStyleRulesForElements(styles[i], filterElements, IGNORE_CLIENTS_CSS_RULES_ATTRIBUTE),
            ];
        }
    }
}

/** Undo function to filterClientsStyleForElements() */
export function unfilterClientsStyleForElements() {
    // unmark given elements with special attribute
    currentClientsFilteredElements.forEach(el => {
        el.removeAttribute(IGNORE_CLIENTS_CSS_RULES_ATTRIBUTE);
    });
    currentClientsFilteredElements = [];

    // restore original selectors
    changedClientsCssRules.forEach(rule => {
        rule.selectorText = rule[ORIGINAL_SELECTOR];
    });
    changedClientsCssRules = [];
}

let changedStylersCssRules: CustomStyleRule[] = [];
let currentStylersFilteredElements: Element[] = [];
const IGNORE_STYLERS_CSS_RULES_ATTRIBUTE = 'ignore-css-rules-stylers';

/** Turn off (change) all CSS rules matching given elements (for stylers style). */
export function filterStylersStyleForElements(filterElements: Element[]) {
    // at first undo previous filtering
    unfilterStylersStyleForElements();

    // mark given elements with special attribute
    currentStylersFilteredElements = [...filterElements];
    currentStylersFilteredElements.forEach(el => {
        el.setAttribute(IGNORE_STYLERS_CSS_RULES_ATTRIBUTE, '');
    });

    const styles = document.querySelectorAll('style');
    for (let i = 0; i < styles.length; i++) {
        if (isStylersStyle(styles[i])) {
            changedStylersCssRules = [
                ...changedStylersCssRules,
                ...filterStyleRulesForElements(styles[i], filterElements, IGNORE_STYLERS_CSS_RULES_ATTRIBUTE),
            ];
        }
    }
}

/** Undo function to filterStylersStyleForElements() */
export function unfilterStylersStyleForElements() {
    // unmark given elements with special attribute
    currentStylersFilteredElements.forEach(el => {
        el.removeAttribute(IGNORE_STYLERS_CSS_RULES_ATTRIBUTE);
    });
    currentStylersFilteredElements = [];

    // restore original selectors
    changedStylersCssRules.forEach(rule => {
        rule.selectorText = rule[ORIGINAL_SELECTOR];
    });
    changedStylersCssRules = [];
}

const SIMPLIFIED_SELECTOR = '_simplifiedSelector';
const ORIGINAL_SELECTOR = '_originalSelector';

function filterStyleRulesForElements(
    styleElement: HTMLStyleElement,
    filterElements: Element[],
    IGNORE_CSS_RULES_ATTRIBUTE: string,
): CustomStyleRule[] {
    if (styleElement.disabled) return [];

    const changedRules: CustomStyleRule[] = [];

    /** Remove all style rules matching given elements
     *  Recursive fn! */
    function evaluateAndRemoveSomeRules(rules: CSSRuleList) {
        for (let index = 0; index < rules.length; index++) {
            const rule = rules[index] as CustomStyleRule | CSSMediaRule;
            if (rule instanceof CSSStyleRule) {
                for (let el of filterElements) {
                    if (elementMatchesRule(el, rule)) {
                        if (!rule[ORIGINAL_SELECTOR]) {
                            rule[ORIGINAL_SELECTOR] = rule.selectorText;
                        }

                        const simplifiedSelector = rule[SIMPLIFIED_SELECTOR];
                        // break down selectors "h1, h2, h3" to single selectors
                        let selectors: string[] = simplifiedSelector.split(/\s*,\s*/);

                        let origSelectors: string[] = rule[ORIGINAL_SELECTOR].split(/\s*,\s*/);

                        // remove selectors matching current element
                        selectors = selectors.map((sel, index) => {
                            return !el.matches(sel)
                                ? sel
                                : addSelectorBeforeLastPseudoElement(
                                      origSelectors[index],
                                      ':where(:not([' + IGNORE_CSS_RULES_ATTRIBUTE + ']))',
                                  );
                        });

                        // replace selector
                        rule.selectorText = selectors.join(', ');

                        changedRules.push(rule);

                        // console.log('orig selector:', rule[ORIGINAL_SELECTOR]);
                        // console.log('simpl selector:', simplifiedSelector);
                        // console.log('new selector:', rule.selectorText);
                        break;
                    }
                }
            } else {
                const isMediaPrint = rule instanceof CSSMediaRule && rule.conditionText === 'print';
                // recursion (ignore media print - for performance reason)
                if (rule.cssRules && !isMediaPrint) evaluateAndRemoveSomeRules(rule.cssRules);
            }
        }
    }

    const sheet = styleElement.sheet;
    evaluateAndRemoveSomeRules(sheet.cssRules);

    return changedRules;
}

export type StoredStyleElement = {
    element: HTMLLinkElement | HTMLStyleElement;
    elementDisabled: boolean;
    sheetDisabled: boolean;
    parentNode: ParentNode;
    nextSibling: ChildNode;
};

function isAttachedToDocument(el: Element) {
    // isConnected support all browsers except IE, so document.body.contains support IE5+
    return el.isConnected !== undefined ? el.isConnected : document.body.contains(el);
}

export type StyleOrigin = 'all' | 'clients' | 'stylers';

/** Pseudo elements in selector when matching target elements (except experimental and with closures: part(), slotted()). */
const pseudoElements = [
    'before',
    'after',
    'backdrop',
    'cue',
    'cue-region',
    'first-letter',
    'first-line',
    'file-selector-button',
    'marker',
    'placeholder',
    'selection',
];

/** Ignored pseudo classes in selector when matching target elements. */
const ignoredPseudoClasses = [
    // User action pseudo-classes
    'hover',
    'active',
    'focus-visible',
    'focus-within',
    'focus',
    // Time-dimensional pseudo-classes
    'current',
    'past',
    'future',
    // Resource state pseudo-classes
    'playing',
    'paused',
    // Location pseudo-classes
    'any-link',
    'local-link',
    'link',
    'visited',
    'target-within',
    'target',
    'scope',
    // Input pseudo-classes
    'autofill',
    'enabled',
    'disabled',
    'read-only',
    'read-write',
    'placeholder-shown',
    'default',
    'checked',
    'indeterminate',
    'blank',
    'valid',
    'invalid',
    'in-range',
    'out-of-range',
    'required',
    'optional',
    'user-invalid',
    // Element display state pseudo-classes
    'fullscreen',
    'modal',
    'picture-in-picture',
];

const pseudoSelectors: string[] = [...pseudoElements.map(sel => '\\:?' + sel), ...ignoredPseudoClasses];
const allPseudoSelectorsRe = '(' + pseudoSelectors.join(')|(') + ')';
// RegExp source with testing data: https://regex101.com/r/Q2zNvi/1
// (?<!^\s*\*?)(:((:before)|(:after)|(hover)))+([^a-z]|$)
const pseudoRegExp = new RegExp('(?<!^\\s\\*\\*?)(\\:(' + allPseudoSelectorsRe + '))+([^a-z]|$)', 'gi');
const lastClosureIndex = 3 + pseudoSelectors.length;

function replacePseudoSelectorWithWildcard(selector: string): string {
    selector = selector.replace(pseudoRegExp, '*$' + lastClosureIndex);
    // now remove all non-single "*", e.g. "div*"
    // RegExp source with testing data: https://regex101.com/r/N0Ii10/1
    // ((^|[a-z0-9]|\)(\*+)(?!=))   |   ((^| )(\*+)(?!$|\s))
    if (selector.trim() != '*') {
        selector = selector.replace(/(^|[a-z0-9]|\]|\))(\*+)(?!=)/gi, '$1').replace(/(^| )(\*+)(?!$|\s)/gi, '$1');
    }
    return selector;
}

// @ts-ignore
window.replacePseudoSelectorWithWildcard = replacePseudoSelectorWithWildcard;
// @ts-ignore
window.pseudoRegExp = pseudoRegExp;
// @ts-ignore
window.lastClosureIndex = lastClosureIndex;

function elementMatchesRule(el: Element, rule: CustomStyleRule) {
    let simplifiedSelector = rule[SIMPLIFIED_SELECTOR];
    if (!simplifiedSelector) {
        // break down selectors "h1, h2, h3" to single selectors
        let selectors = rule.selectorText.split(/\s*,\s*/);

        // remove pseudo elements ::before, ::after, ... from the matching selector
        // filter out pseudo classes :hover, ...
        // except solo selectors: *, ::before, ::after, :hover
        selectors = selectors.map(replacePseudoSelectorWithWildcard);

        // replace selector
        simplifiedSelector = rule[SIMPLIFIED_SELECTOR] = selectors.join(', ');
    }
    return el.matches(simplifiedSelector);
}

const allPseudoElementSelectorsRe = '(' + pseudoElements.map(sel => '\\:?' + sel).join(')|(') + ')';
// (.*?)($|(\:(\:before|\:after| ... )))
const pseudoElementsRegExp = new RegExp('(.*?)($|\\:(' + allPseudoElementSelectorsRe + '))', 'i');

/**
 * Add addon selector to given selector on the last element before pseudo element (if present).
 * @param selector Current whole selector.
 * @param addonSelector Addon selector to add whole selector.
 *
 * @example
 *  addSelectorBeforeLastPseudoElement('body h3 > a:hover', '.xxx') => 'body h3 > a:hover.xxx'
 *  addSelectorBeforeLastPseudoElement('body h3 > a::before', '.xxx') => 'body h3 > a.xxx::before'
 */
function addSelectorBeforeLastPseudoElement(selector: string, addonSelector: string) {
    return selector.replace(pseudoElementsRegExp, '$1' + addonSelector + '$2');
}

interface CustomStyleRule extends CSSStyleRule {
    _simplifiedSelector: string;
    _originalSelector: string;
}
