import { ReplicaAPI } from '../shared/types/interfaces/replica.types';
import { ReplicaMessage } from '../shared/types/messages/replica-message.types';
import { CssUpdates } from '../shared/types/sass-compiler.types';
import { compareFilePathsForCompile } from '../shared/utils/multifile/multifile-sort';
import { PlatformAPI } from './../shared/types/interfaces/platform.types';
import { errorProgressBar, startProgressBar, successProgressBar } from './compile-progressbar';
import { getLogger } from './logging';
import {
    disableAllStyles,
    enableAllStyles,
    filterClientsStyleForElements,
    filterStylersStyleForElements,
    StoredStyleElement,
    unfilterClientsStyleForElements,
    unfilterStylersStyleForElements,
} from './../shared/utils/disable-stylesheets';

const logger = getLogger('ReplicaService');
export class ReplicaService {
    public isSelectionModeOn = false;
    public isGlobalCssChecked = false;
    public parent = window.parent as any;

    public highlightedElement: HTMLElement | null;
    public highlightElement1: HTMLElement;
    public highlightElement2: HTMLElement;

    private lastCompileDuration = 100; // default
    private stopwatch = 0;

    private id?: number = null;

    private removedClientsStyleElements: StoredStyleElement[] = [];
    private isDisabledClientsStyles = false;
    private removedStylersStyleElements: StoredStyleElement[] = [];
    private isDisabledStylersStyles = false;

    public get platformApi(): PlatformAPI {
        return this.parent['Platform'];
    }

    public get isOpenedInPlatform(): boolean {
        return !!this.platformApi;
    }

    public bindedStopSelectionModeWithEscKey: (e: KeyboardEvent) => void;

    constructor(id?: number) {
        this.id = id;
        this.bindedStopSelectionModeWithEscKey = this.stopSelectionModeWithEscKey.bind(this);
        if (window.opener) {
            this.parent = window.opener;
        } else if (window.parent == window) {
            logger.warn('window.parent refers to itself');
        } else {
            this.parent = window.parent;
        }
    }

    public createApi(): void {
        const api: ReplicaAPI = {
            onStartCompileSass: () => {
                startProgressBar(this.lastCompileDuration);
                this.stopwatch = Date.now();
            },
            onAbortCompileSass: () => {
                errorProgressBar();
            },
            applyCss: (updates: CssUpdates) => {
                console.log('updating css', updates);
                // calculate average compile time to predict progressbar
                if (this.stopwatch != 0) {
                    const duration = Date.now() - this.stopwatch;
                    this.lastCompileDuration = this.lastCompileDuration * 0.65 + duration * 0.35;
                }

                for (const path in updates) {
                    const css = updates[path];
                    // Set css
                    const styleTag = this.getGlobalStyleTagForPath(path);
                    styleTag.innerHTML = css;
                }

                successProgressBar();

                // turn off "global-css" (check just once)
                if (!this.isGlobalCssChecked) {
                    const globalCss = document.querySelector('[stylers-cloud="global-css"]') as HTMLStyleElement;

                    if (globalCss) {
                        // @ts-ignore
                        if (globalCss.disabled !== undefined) {
                            // @ts-ignore
                            globalCss.disabled = true;
                            // @ts-ignore
                            // special flag for <style stylers-cloud="global-css" when turned off
                            globalCss.turnedOff = true; // info for turning on/off stylers style (see Replica API)
                        } else {
                            globalCss.remove();
                        }

                        this.isGlobalCssChecked = true;
                    }
                }
            },

            elementHover: (element: HTMLElement) => {
                this.highlightElement(element);
            },

            elementBlur: () => {
                this.clearHighlightedElement();
            },

            elementSelect: (element: HTMLElement) => {
                // console.log('Replica element select event:', element);
                // not implemented yet further in replica but event is functional
            },

            startSelectionMode: () => {
                this.startSelectionMode();
            },

            stopSelectionMode: () => {
                this.stopSelectionMode();
            },

            disableClientsStyles: () => {
                if (!this.isDisabledClientsStyles) {
                    disableAllStyles(this.removedClientsStyleElements);
                    this.isDisabledClientsStyles = true;
                }
            },

            enableClientsStyles: () => {
                if (this.isDisabledClientsStyles) {
                    enableAllStyles(this.removedClientsStyleElements);
                    this.isDisabledClientsStyles = false;
                }
            },

            disableStylersStyles: () => {
                if (!this.isDisabledStylersStyles) {
                    disableAllStyles(this.removedStylersStyleElements, 'stylers');
                    this.isDisabledStylersStyles = true;
                }
            },

            enableStylersStyles: () => {
                if (this.isDisabledStylersStyles) {
                    enableAllStyles(this.removedStylersStyleElements);
                    this.isDisabledStylersStyles = false;
                }
            },

            filterClientsStylesForElements: (elements: Element[]) => {
                filterClientsStyleForElements(elements);
            },

            unfilterClientsStyles: () => {
                unfilterClientsStyleForElements();
            },

            filterStylersStylesForElements: (elements: Element[]) => {
                filterStylersStyleForElements(elements);
            },

            unfilterStylersStyles: () => {
                unfilterStylersStyleForElements();
            },
        };
        // @ts-ignore
        window['Replica'] = api;
        logger.info('ReplicaAPI ready');
    }

    public startSelectionMode(): void {
        document.removeEventListener('keydown', this.bindedStopSelectionModeWithEscKey);
        document.addEventListener('keydown', this.bindedStopSelectionModeWithEscKey);
        this.isSelectionModeOn = true;
    }

    public stopSelectionMode(): void {
        document.removeEventListener('keydown', this.bindedStopSelectionModeWithEscKey);
        this.clearHighlightedElement();
        this.isSelectionModeOn = false;
    }

    public stopSelectionModeWithEscKey(e: KeyboardEvent): void {
        if (e.key === 'Escape' && this.isSelectionModeOn) {
            this.stopSelectionMode();
            this.platformApi && this.platformApi.onReplicaStopSelectionMode();
        }
    }

    public replicaReady(): void {
        logger.info('replicaReady');
        this.platformApi &&
            this.platformApi.replicaReady({ replicaWindow: window, replicaDocument: document, id: this.id });
    }

    public onReplicaElementHover(target: HTMLElement): void {
        this.highlightElement(target);
        this.platformApi && this.platformApi.onReplicaElementHover(target);
    }

    public onReplicaElementBlur(): void {
        this.clearHighlightedElement();
        this.platformApi && this.platformApi.onReplicaElementBlur();
    }

    public onReplicaElementSelect(target: HTMLElement): void {
        this.clearHighlightedElement();
        this.platformApi && this.platformApi.onReplicaElementSelect(target);
    }

    public onReplicaStopSelectionMode(): void {
        this.clearHighlightedElement();
        this.platformApi && this.platformApi.onReplicaStopSelectionMode();
    }

    public createHighlightElements(): void {
        if (!this.highlightElement1) {
            const commonStyle = {
                position: 'fixed',
                pointerEvents: 'none',
                boxSizing: 'border-box',
                zIndex: (2147483647 - 1000).toString(), // maximum - 1k
            };
            this.highlightElement1 = document.createElement('div');
            this.highlightElement1.setAttribute('stylers-cloud', 'highlight');
            setInlineStyle(this.highlightElement1, {
                ...commonStyle,
                background: 'white',
                border: '0 solid white',
                opacity: '0.15',
                // to change original graphic
                // (for the case when blue highlight is applied on blue background)
                mixBlendMode: 'difference',
            });

            this.highlightElement2 = document.createElement('div');
            this.highlightElement2.setAttribute('stylers-cloud', 'highlight');
            setInlineStyle(this.highlightElement2, {
                ...commonStyle,
                background: '#08f',
                border: '0 solid orange',
                opacity: '0.25',
            });
        }
    }

    public highlightElement(el: HTMLElement): void {
        if (!el) {
            console.warn('higlighting', el);
            return;
        }
        if (![1, 3, 9].includes(el.nodeType) || el.nodeName == 'NOSCRIPT') {
            /*
                1 ELEMENT_NODE
                3 TEXT_NODE
                9 DOCUMENT_NODE
             */
            return;
        }
        this.createHighlightElements();
        this.highlightedElement = el;
        this.repositionHighlightedElement();
        if (!this.highlightElement1.parentElement) document.body.appendChild(this.highlightElement1);
        if (!this.highlightElement2.parentElement) document.body.appendChild(this.highlightElement2);
    }

    public repositionHighlightedElement(): void {
        let elMbr;
        let comp;
        if (this.highlightedElement.nodeType == 3 /* text */) {
            const range = new Range();
            range.selectNode(this.highlightedElement);
            elMbr = range.getBoundingClientRect();
            range.detach();
            comp = window.getComputedStyle(this.highlightedElement.parentElement);
        } else {
            elMbr = this.highlightedElement.getBoundingClientRect();
            comp = window.getComputedStyle(this.highlightedElement);
        }

        const css = {
            left: elMbr.left + 'px',
            top: elMbr.top + 'px',
            width: elMbr.width + 'px',
            height: elMbr.height + 'px',
            borderTopWidth: comp.paddingTop,
            borderBottomWidth: comp.paddingBottom,
            borderLeftWidth: comp.paddingLeft,
            borderRightWidth: comp.paddingRight,
            display: 'block',
        };
        setInlineStyle(this.highlightElement1, css);
        setInlineStyle(this.highlightElement2, css);
    }

    public clearHighlightedElement(): void {
        // for performance reasons (lighting fast nodes hover in editor),
        // it is not removed from document, but just hidden
        if (this.highlightElement1) this.highlightElement1.style.display = 'none';
        if (this.highlightElement2) this.highlightElement2.style.display = 'none';
        this.highlightedElement = null;
    }

    public replicaClosing(): void {
        if (this.platformApi) {
            this.platformApi.replicaClosing();
        }
    }

    public postReplicaMessage(response: ReplicaMessage.ReplicaMessage): void {
        this.parent.postMessage(response, '*');
    }

    private getGlobalStyleTagForPath(path: string): Element {
        const existingEditorCss = document.querySelector(
            `[stylers-cloud="editor-css-file"][data-stylers-cloud-path="${path}"]`,
        );

        // Element already exists
        if (existingEditorCss) return existingEditorCss;

        const newEditorCss = document.createElement('style');
        newEditorCss.setAttribute('type', 'text/css');
        newEditorCss.setAttribute('stylers-cloud', 'editor-css-file');
        newEditorCss.dataset.stylersCloudPath = path;

        const allEditorStyleTags = document.querySelectorAll<HTMLElement>('[stylers-cloud="editor-css-file"]');
        let precedingStyleTag: Element = null;
        allEditorStyleTags.forEach(el => {
            if (compareFilePathsForCompile(el.dataset.stylersCloudPath, path) <= 0) {
                precedingStyleTag = el;
            }
        });

        // Append in correct order
        if (precedingStyleTag) {
            precedingStyleTag.parentNode.insertBefore(newEditorCss, precedingStyleTag.nextSibling);
            return newEditorCss;
        }

        // Append after global css
        const globalCss = document.querySelector('[stylers-cloud="global-css"]');
        if (globalCss) {
            globalCss.parentNode.insertBefore(newEditorCss, globalCss.nextSibling);
            return newEditorCss;
        }

        // Append to end of head
        const headTag = document.getElementsByTagName('HEAD')[0];
        headTag.appendChild(newEditorCss);
        return newEditorCss;
    }
}

/** Set inline style with flag important. */
function setInlineStyle(el: HTMLElement, style: { [prop: string]: string }) {
    for (let prop in style) {
        if (style.hasOwnProperty(prop)) {
            const dashedProp = prop.replace(/([A-Z])/g, '-$1').toLowerCase();
            el.style.setProperty(dashedProp, style[prop], 'important');
        }
    }
}
