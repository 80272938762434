import { TaskState } from './task.types';

export const todoStatesTaskManager = [TaskState.new, TaskState.analyzing, TaskState.rejected, TaskState.completing];
export const todoStatesStyler = [TaskState.queued, TaskState.inProgress, TaskState.disapproved];

export function isDevEnv() {
    try {
        const json = window.localStorage.getItem('stylers-cloud');
        if (!json) return false;
        const storedConfig = JSON.parse(json);
        // only when `overrideConfig`===true read stored config
        return storedConfig && storedConfig.overrideConfig && storedConfig.env === 'dev';
    } catch (e) {
        if (e instanceof DOMException && e.INVALID_ACCESS_ERR) {
            console.warn("stylers.cloud SDK doesn't work in incognito mode for some pages");
        }
        throw e;
    }
}

export const API_URL_DEV = 'https://dev.api.stylers.cloud/';
export const API_URL_STAGE = 'https://stage.api.stylers.cloud/';
export const API_URL_PROD = 'https://api.stylers.cloud/';

export const CDN_URL_DEV = 'https://dev.assets.stylers.cloud/';
export const CDN_URL_STAGE = 'https://stage.assets.stylers.cloud/';
export const CDN_URL_PROD = 'https://assets.stylers.cloud/';

export const REPLICA_URL_LOCAL = 'http://localhost:9000/';
export const REPLICA_URL_DEV = 'https://dev.replica.stylers.cloud/';
export const REPLICA_URL_STAGE = 'https://stage.replica.stylers.cloud/';
export const REPLICA_URL_PROD = 'https://replica.stylers.cloud/';

export const ASSET_MANAGER_DEV = 'https://dev.amng.stylers.cloud/';
export const ASSET_MANAGER_STAGE = 'https://stage.amng.stylers.cloud/';
export const ASSET_MANAGER_PROD = 'https://amng.stylers.cloud/';

export const CLIENT_APP_DEV = 'https://dev.client.stylers.cloud/';
export const CLIENT_APP_STAGE = 'https://stage.client.stylers.cloud/';
export const CLIENT_APP_PROD = 'https://client.stylers.cloud/';

export const EDITOR_URL_LOCAL = 'http://localhost:4201/';
export const EDITOR_URL_DEV = 'https://dev.editor.stylers.cloud/';
export const EDITOR_URL_STAGE = 'https://stage.editor.stylers.cloud/';
export const EDITOR_URL_PROD = 'https://editor.stylers.cloud/';

export const dayInMs = 1000 * 60 * 60 * 24;

export const platformEfficiencyCoefficient = 1;
export const stylingToCustomerEffortCoefficient = 2;

export const STYLERS_CLOUD_ATTRIBUTE_NAME = 'stylers-cloud';
